import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'register',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: '',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'verification',
    loadChildren: () => import('./verification-page/verification-page.module').then(m => m.VerificationPageModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./new-registration/new-registration.module').then(m => m.NewRegistrationModule),
  },
  {
    path: 'newregistration',
    loadChildren: () => import('./wolken-registration/wolken-registration.module').then(m => m.WolkenRegistrationModule)
  },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
