import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppDetailsService } from '../core/app-details/app-details.service';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private appDetailsService: AppDetailsService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.checkValidation();
  }

  checkValidation() {
    if (this.appDetailsService.getBackendApiUrl()) {
      return of(true);
    } else {
      const input = {
        domain: window.location.hostname
        // domain: 'oneclick-registration.wolkencare.com'
      }
      const formData: FormData = new FormData();
      formData.append('data', JSON.stringify(input));
      return this.appDetailsService.getCompanyConfigDetails(formData)
        .pipe(
          switchMap((response) => {
              const companyDetails = response.data.companyDetails;
              this.appDetailsService.setAppDetails(companyDetails);
              return this.appDetailsService.fetchConfigDetails();
              // return of(true);
          }), catchError((error) => {
            return of(false);
          })
        );
    }
  }

}
