import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationPageComponent } from './verification-page.component';
import { VerificationPageRoutingModule } from './verification-page-routing.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ VerificationPageComponent ],
  imports: [
    CommonModule,
    VerificationPageRoutingModule,
    SharedModule
  ],
  exports: [
    VerificationPageComponent
  ]
})
export class VerificationPageModule { }
