import { Component, OnInit } from '@angular/core';
import { VerificationService } from './verification.service';
import { AppDetailsService } from 'src/app/core/app-details/app-details.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verification-page',
  templateUrl: './verification-page.component.html',
  styleUrls: ['./verification-page.component.scss']
})
export class VerificationPageComponent implements OnInit {
  public companyDetails: any;
  public recievedParams: any;
  public verificationMessage: any;
  public verificationStatus: any;
  public onSuccess = true;
  public verifyingEmail: any;
  public isLoginSuccess = false;
  public isLoginFails = false;
  public isLoginSupportText = false;
  public responseMsgSubStrBold: any;
  public responseMsgSubStrStart: any;

  constructor(public verificationService: VerificationService,
              private appDetailsService: AppDetailsService,
              private router: ActivatedRoute) { }

  ngOnInit() {
    if (!this.appDetailsService.getAppDetails()) {
      const input = {
        domain: window.location.hostname,
        // domain: 'oneclick-staging.wolkenservicedesk.com'
      };
      const formData: FormData = new FormData();
      formData.append('data', JSON.stringify(input));
      this.appDetailsService.getCompanyConfigDetails(formData).subscribe((value) => {
        if (value && value.data && value.data.hasOwnProperty('companyDetails')) {
          this.companyDetails = value.data.companyDetails;
          this.appDetailsService.setAppDetails(this.companyDetails);
          this.verifyEmailResponse();
         }
      }, (error) => {
        console.log(error);
      });
    }
    this.recievedParams = this.router.snapshot.queryParamMap.get('hexa');
  }

verifyEmailResponse() {
  this.onSuccess = true;
  this.verificationMessage = ' ';
  this.verifyingEmail  = true;
  this.isLoginSuccess = false;
  this.isLoginFails = false;
  this.isLoginSupportText = false;
  this.verificationService.verifyEmail(this.recievedParams).subscribe(
    (value: any) => {
      if (value) {
        this.verifyingEmail = false;
        this.verificationMessage = value.message;
        this.verificationStatus = value.status;
        if (this.verificationMessage.startsWith('Login')) {
          this.isLoginSuccess = true;
          this.responseMsgSubStrBold = this.verificationMessage.substring(35, this.verificationMessage.length);
          this.responseMsgSubStrStart = this.verificationMessage.substring(0, 35);
          this.onSuccess = false;
        } else if (this.verificationMessage.startsWith('Email')) {
          this.isLoginFails = true;
          this.responseMsgSubStrBold = this.verificationMessage.substring(39, this.verificationMessage.length);
          this.responseMsgSubStrStart = this.verificationMessage.substring(0, 39);
          this.onSuccess = false;
        } else {
          this.isLoginSupportText = true;
        }
        this.onSuccess = false;
      }
    },
    (error) => {
      this.verificationMessage = error.error.message;
      if(error.status === 0) {
      setTimeout(() => {
        this.onSuccess = false;
        this.verifyingEmail = false;
        this.verificationStatus = true;
        this.verificationMessage = 'Login information has been sent to your registered Email';
        this.responseMsgSubStrStart = this.verificationMessage;
      }, 15000);
    } else {
      this.onSuccess = false;
    }
    });

}
}


