import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public enableGAnalytics = false;
  constructor(private router: Router) {
        let self = this;
        let xhttp = new XMLHttpRequest();
        xhttp.open("GET", window.origin + '/assets/config.json', true);
        xhttp.responseType = 'json';
        xhttp.onload = function () {
          if (this.status === 200) {
            if (this.response) {
              self.enableGAnalytics = this.response.enableGoogleAnalytics ? this.response.enableGoogleAnalytics : false;
              if(self.enableGAnalytics){
                 self.loadAnalyticsScript();
              }
            }
          }
        }
        xhttp.send();
   }

ngOnInit() {
// this.setUpAnalytics();
}

setUpAnalytics() {
  this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
          gtag('config', 'G-DKS6ZRW1V9',
              {
                  page_path: event.urlAfterRedirects
              }
          );
      });
}

loadAnalyticsScript(){
 // register google tag manager
 const gTagManagerScript = document.createElement('script');
 gTagManagerScript.async = true;
 gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${'G-DKS6ZRW1V9'}`;
 document.head.appendChild(gTagManagerScript);

 // register google analytics
 const gaScript = document.createElement('script');
 gaScript.innerHTML = `
   window.dataLayer = window.dataLayer || [];
   function gtag() { dataLayer.push(arguments); }
   gtag('js', new Date());
   gtag('config', '${'G-DKS6ZRW1V9'}');
 `;
 document.head.appendChild(gaScript);
}
}
