import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { RegistrationServiceService } from './registration-form/registration-service.service';
import { SharedModule } from '../shared/shared.module';
import { RegisterRoutingModule } from '../registration/register-routing.module';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [RegistrationFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTooltipModule,
    RegisterRoutingModule
  ],
  exports: [
    RegistrationFormComponent
  ],
  providers : [
    RegistrationServiceService,
  ]
})

export class RegistrationModule { }
