import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
private url = 'https://signup-oneclick.wolkencare.com/care/register/verifyEmail.do';

  constructor(private http: HttpClient) { }

  verifyEmail(hexaVal) {
    const params = new HttpParams().set('hexa', hexaVal);
    return this.http.get('care/register/verifyEmail.do', {params});
  }
}

//Sorry. Verification failed!! We are checking the cause of failure meanwhile our Support Team will get in touch with you and help!
//Login information has been sent to +email address
// EMAIL_ALREADY_VERIFIED="Email verification is already done for ";