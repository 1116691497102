import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { AuthGuardService } from '../core/auth-guard.service';


const routes: Routes = [
  {
    path : 'register',
    component : RegistrationFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path : '',
    component : RegistrationFormComponent,
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterRoutingModule { }
