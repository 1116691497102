import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class AppDetails {
  baseUrl: string;
  oktaEnabled: boolean;
  oktaUrl: string;
}

export class ConfigDetails {
  domainPrefix: string;
  restrictPlusOperator: boolean;
  enableGoogleAnalytics: boolean;
  wolkenMasterApiUrl;
}

@Injectable({
  providedIn: 'root'
})
export class AppDetailsService {
  private configFileName = 'assets/config.json?version=' + this.genRandomString(64);
 // private companyConfigDetailsBaseUrl = this.getwolkenMasterApiUrl()+'/company/getCompanySsoDetails';
  private oneClickRequestHeaderKey = 'wolken_token';
  private oneClickRequestHeaderValue = 'jnoov2g925uicm6ncg6qncv46l';
  private wolkenMasterRequestAutorization = 'Basic Y1VyeWF0ZUlrU2U6YnVzdFRlV29sa2Vu';
  private appDetails: AppDetails;
  private configDetails: ConfigDetails;
  constructor(private http: HttpClient) { }

  getAppDetails() {
    return this.appDetails;
  }

  setAppDetails(appDetails) {
    this.appDetails = appDetails;
  }

  getConfigDetails() {
    return this.configDetails;
  }

  setConfigDetails(configDetails) {
    this.configDetails = configDetails;
  }

  getCompanyConfigDetails(formData): Observable<any> {
    return this.http.get(this.configFileName).pipe(
      switchMap((response: any) => {
        if (response['wolkenMasterApiUrl']) {
          return this.http.post(response['wolkenMasterApiUrl'] + '/company/getCompanySsoDetails', formData)
        } 
      }));
    //return this.http.post(this.companyConfigDetailsBaseUrl, formData);
  }

  getBackendApiUrl() {
    if (this.appDetails) {
      return this.appDetails.baseUrl;
    } else {
      return;
    }
  }

  getRequestHeaderKey() {
      return this.oneClickRequestHeaderKey;
  }

  getRequestHeaderValue() {
    return this.oneClickRequestHeaderValue;
  }

  getRequestAuthorization() {
    return this.wolkenMasterRequestAutorization;
  }

  genRandomString(length) {
    const randomCharset = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let random = '';
    for (let c = 0, cl = randomCharset.length; c < length; ++c) {
      random += randomCharset[Math.floor(Math.random() * cl)];
    }
    return random;
  }
 
  fetchConfigDetails() {
    return this.http.get(this.configFileName)
        .pipe(
          switchMap((response) => {
            this.setConfigDetails(response);
              return of(true);
          }), catchError((error) => {
            return of(false);
          })
        );
  }

  getDomainPrefix(){
    if(this.configDetails && this.configDetails.domainPrefix){
      return this.configDetails.domainPrefix;
    }
  }

  getRestrictOperator(){
    if(this.configDetails && this.configDetails.restrictPlusOperator){
      return this.configDetails.restrictPlusOperator;
    } else {
      return false;
    }
  }

  enableGoogleAnalytics(){
    if(this.configDetails && this.configDetails.enableGoogleAnalytics){
      return this.configDetails.enableGoogleAnalytics;
    } 
  }


}
