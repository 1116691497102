import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService } from './auth-guard.service';
import { AuthInterceptor } from './auth.interceptor';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],  providers: [
    AuthGuardService,
    {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true
    }
  ],
})
export class CoreModule { }
