
<div fxLayout="column" fxLayoutAlign="center center">
<div fxLayoutAlign="center center" fxLayout="row" *ngIf="!isFormLoading && !successPage" class="displayFn outerFlex">
  <div class="flex-container displayFn" fxLayoutGap="20px">
    <div fxFlex="1 1 55%" class="login-section">
      <div class="wolken-logo">
        <img src="assets/images/WOLKEN_CARE_LOGO.svg">
      </div>
      <div class="register-header">
        Create Your Account
      </div>
      <form [formGroup]="registerForm" (ngSubmit)="register(registerForm)">
        <div fxLayout="row wrap" fxLayoutGap="18px" fxLayoutGap.xs="0px"  fxLayout.xs="column">
          <mat-form-field fxFlex="1 1 0" appearance="outline">
            <mat-label>First Name<span class="asterisk">*</span></mat-label>
               <input matInput placeholder=" " formControlName="userfName" autocomplete="off" >
                 <mat-error *ngIf="registerForm.get('userfName').hasError('required')">
                     Name is Required
                 </mat-error>
                 <mat-error *ngIf="registerForm.get('userfName').hasError('maxlength')">Maximum characters exceeded
                </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="1 1 0" appearance="outline">
            <mat-label>Last Name</mat-label>
               <input matInput placeholder=" " formControlName="userlName" autocomplete="off">
               <mat-error *ngIf="registerForm.get('userlName').hasError('maxlength')">Maximum characters exceeded
              </mat-error>
          </mat-form-field>
        </div>

      <div fxLayout="row wrap" fxLayoutGap="18px" fxLayoutGap.xs="0px" fxLayout.xs="column">
        <mat-form-field fxFlex="1 1 0" appearance="outline">
            <mat-label>Email<span class="asterisk">*</span></mat-label>
                 <input matInput placeholder=" " formControlName="email" (change)="emailValidation($event.target.value)" autocomplete="off">
                 <div class="field-margin-sufix" fxLayout="row" matSuffix *ngIf="emailCheck" >
                  <mat-icon color="primary">
                    <img src="assets/images/CHECK_CIRCLE.svg">
                  </mat-icon>
                </div>
                 <mat-error *ngIf="registerForm.get('email').hasError('required')">Email is Required
                 </mat-error>
                 <mat-error *ngIf="registerForm.get('email').hasError('notValidEmailId')">Enter Valid Email
                 </mat-error>
                 <mat-hint *ngIf="!emailCheck">{{emailResponseMsg}}</mat-hint>
          </mat-form-field>

        <mat-form-field  fxFlex="1 1 0" appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input matInput placeholder=" " formControlName="phoneNumber">
            <div class="field-margin-sufix" fxLayout="row" matSuffix *ngIf="registerForm.get('phoneNumber').value && registerForm.get('phoneNumber').valid" >
              <mat-icon color="primary">
                <img src="assets/images/CHECK_CIRCLE.svg">
              </mat-icon>
            </div>
            <mat-error *ngIf="registerForm.get('phoneNumber').hasError('pattern')">Please enter
              numbers only</mat-error>
              <mat-error *ngIf="registerForm.get('phoneNumber').hasError('maxlength')">PLease Enter a Valid Phone Number
              </mat-error>
          </mat-form-field>

      </div>
        <div class="full-width">
          <mat-form-field appearance="outline">
            <mat-label>Company Name<span class="asterisk">*</span></mat-label>
            <input matInput placeholder=" " formControlName="companyname" (input)="domainCreation($event.target.value); domainValidation()"
             autocomplete="off">
            <mat-error *ngIf="registerForm.get('companyname').hasError('required')">
              <p>Company Name is Required</p>
            </mat-error>
            <mat-error *ngIf="registerForm.get('companyname').hasError('maxlength')">Maximum characters exceeded
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Domain Name<span class="asterisk">*</span></mat-label>
            <span *ngIf="domainPrefix && registerForm.get('companyname').value" matPrefix>{{domainPrefix}}</span>
            <input matInput placeholder=" " formControlName="domain"  (input)="domainValidation();omit_special_char($event)" (keyup)="$event.target.value = $event.target.value.toLowerCase()">
            <div fxLayout="row" matSuffix>
              <span class="port-domain" *ngIf="registerForm.get('companyname').value">.wolkencare.com</span>
              <mat-icon class="domain-validation-icon" color="primary" *ngIf="showSuccessIcon">
                <img src="assets/images/CHECK_CIRCLE.svg">
              </mat-icon>
              <mat-icon class="domain-validation-icon icon-style2 icon-sm" *ngIf="showErrorIcon">error</mat-icon>
              <mat-spinner *ngIf="showSpinner" mode="indeterminate" diameter="17"></mat-spinner>
            </div>
            <!-- <mat-icon matSuffix  *ngIf="showSuccessIcon" class="icon-sm" class="icon-style1">check_circle</mat-icon> -->
            <mat-error *ngIf="registerForm.get('domain').hasError('required')">
              <p>Domain Name is Required</p>
            </mat-error>
            <mat-hint *ngIf="showErrorIcon">
              <p *ngIf="!domainErrorMsg && registerForm.get('domain').hasError('notValid')">Domain name already exists.</p>
              <p *ngIf="domainErrorMsg && registerForm.get('domain').hasError('notValid')">{{domainErrorMsg}}</p>
            </mat-hint>
            <mat-hint *ngIf="specialCharAlert">
              <p>Special Characters are not allowed</p>
            </mat-hint>
            <mat-error *ngIf="registerForm.get('domain').hasError('maxlength')">Maximum characters exceeded
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
              <mat-label>Location<span class="asterisk">*</span></mat-label>
              <input type="text" matInput formControlName="locationName" [matAutocomplete]="autoLocation" 
              (input)="checkIfLocatiomEmptyInput()" autocomplete="off">
    
              <mat-autocomplete #autoLocation="matAutocomplete" [displayWith]="displayLocationFn">
                <mat-option *ngFor="let option of LocationFilter | async" [value]="option">
                  {{option.locationName}}
                </mat-option>
              </mat-autocomplete>
              <div fxLayout="row"class="field-margin-sufix" fxLayout="row" matSuffix >
                <mat-icon class="material-icons-round">
                  <img src="assets/images/arrow_drop_down.svg">
                </mat-icon>
                <mat-icon class="error-btn" color="primary" *ngIf="registerForm.get('locationName').value && registerForm.get('locationName').valid" >
                  <img src="assets/images/CHECK_CIRCLE.svg">
                </mat-icon>
               <mat-icon *ngIf="registerForm.get('locationName').hasError('notValid')" matTooltip="Please Select Valid Location" class="icon-sm" class="icon-style2 error-btn">error</mat-icon>
              </div>
              <mat-error *ngIf="registerForm.get('locationName').hasError('required')">Please Select a Location
              </mat-error>
            </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Timezone<span class="asterisk">*</span></mat-label>
          <input type="text" matInput formControlName="timezone" [matAutocomplete]="auto" 
          (input)="checkIfTimezoneEmptyInput()" autocomplete="off">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnc">
            <mat-option *ngFor="let option of timeZoneFilter | async" [value]="option">
              {{option.timeZoneName}}
            </mat-option>
          </mat-autocomplete>
          <div fxLayout="row"class="field-margin-sufix" fxLayout="row" matSuffix >
            <span class="drop-down" matSuffix>
              <mat-icon class="material-icons-round">
                <img src="assets/images/arrow_drop_down.svg">
              </mat-icon>
            </span>
            <mat-icon class="error-btn" color="primary" matTooltip="Please Select Valid TimeZone" *ngIf="registerForm.get('timezone').value && registerForm.get('timezone').valid" >
              <img src="assets/images/CHECK_CIRCLE.svg">
            </mat-icon>
           <mat-icon *ngIf="registerForm.get('timezone').hasError('notValid')" class="icon-sm" class="icon-style2 error-btn">error</mat-icon>
            </div>
          <mat-error *ngIf="registerForm.get('timezone').hasError('required')">Please Select a TimeZone
          </mat-error>
        </mat-form-field>
        </div>    

        <div class="btn-spacing" align="end">
          <div fxLayoutAlign="space-between start start" fxLayoutGap="10px">
            <a class="login-link" href="#"> </a>
            <!-- <mat-checkbox formControlName="termsCondition" class="tandcCheckbox">
             <span class="terms-conditions"> I accept the </span><a class="term-link" href="https://www.google.com" target="_blank">Terms and Conditions</a>
            </mat-checkbox> -->
            <div fxLayout="column">
            <button mat-raised-button class="btn-style" type="submit" [disabled]="registerForm.invalid || checkIfAgreedTerms() || specialCharAlert" [ngClass]="{'disable-btn': isBtnLoading}">
              GET STARTED
              <div class="lds-ellipsis" *ngIf="isBtnLoading">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
            <span *ngIf="isBtnLoading" class="setup-msg">Setting up your instance</span>
          </div>
          </div>
        </div>
      </form>
    </div>

    <!-- Right Side Section -->
    <div fxLayout="column" fxLayoutGap="10px" class="slide-section">
      <div class="logo-design">
        <img src="assets/images/SIGNUP_ILLUSTRATION.svg">
      </div>
      <div class="content-style">
        <ul>
          <li>
            Automate and Optimize your Customer Service Solutions
          </li>
          <li>
            Wolken Care can be set up for use within minutes and is completely configurable.
          </li>
          <li>
            With Wolken Care,have omnichannel presence and Intelligent solutions for enhanced CX
            </li>
            <li>
              Wolken Care is completely configurable by the users themselves and requires little to no external support.
            </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<div fxLayoutAlign="center center" fxLayout="row" *ngIf="successPage" class="success-page">
  <div *ngIf="isSuccess && showSuccessBox"></div>
  <!-- register -->
  <div class="successModal" align="center">
    <div class="modal-wolken-logo">
      <img src="assets/images/WOLKEN_CARE_LOGO.svg">
    </div>
    <div class="register-header">
      Check your Inbox
      
    </div>
    <div class="success-img">
    <img src="assets/images/MAIL.png">
    </div>

  <div class="responseMsg">
      <p>{{responseMsgSubStrStart}}<span style="color:black;font-weight:400;">{{responseMsgSubStrBold}}</span></p>
  </div>
      <!-- <button class="modal-btn-style" [routerLink]="['register']">
    TRY AGAIN
      </button> -->
      <div class="question-email">
        <span>Question?Email us at <a href="#">support@wolkensoftware.com</a></span>
      </div>
  </div>

  <div *ngIf="errorMsg && showSuccessBox">
          <div *ngIf="errorMsg && showSuccessBox" class="errorModal">
            <div class="errorModal-header" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
              <mat-icon class="icon-sm" class="icon-style">error</mat-icon>
              <span> Error </span>
            </div>
            <div class="responseMsg">
              <div> {{responseMsg}} </div>
            </div>
          </div>
   </div>
  </div>

</div>