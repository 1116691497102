import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppDetailsService } from './app-details/app-details.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public url: any;
  constructor(private appDetailsService: AppDetailsService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('care')) {
      this.url = this.appDetailsService.getBackendApiUrl() + request.url;
      request = request.clone({
      url: this.url,
      // headers: request.headers.set(this.appDetailsService.getRequestHeaderKey(), this.appDetailsService.getRequestHeaderValue()),
    });
      return next.handle(request);
    } else {
      this.url = '';
      request = request.clone({
        url: this.url,
        setHeaders: {
          Authorization: this.appDetailsService.getRequestAuthorization()
          }
      });
      return next.handle(request);
    }
    // request = request.clone({
    //   url: this.url,
    //   headers: request.headers.set('wolken_token', 'jnoov2g925uicm6ncg6qncv46l'),
    // });
    // console.log('request', request);
    // return next.handle(request);
  }
}
