import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { RegistrationServiceService } from './registration-service.service';
import { CookieService } from 'ngx-cookie-service';
import { AppDetailsService } from 'src/app/core/app-details/app-details.service';
import { Observable } from 'rxjs/Observable';
import { startWith, map } from 'rxjs/operators';
import 'rxjs/add/observable/timer';

export interface TimeZone {
  aliasName: string;
  offset: string;
  timeZoneDisplayName: string;
  timeZoneId: number;
  timeZoneName: string;
}

export class locationObj {
  roleName: any;
  locationName: any;
  constructor(public selectedObj: string) { }
}

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {
  public registerForm: FormGroup;
  public timezoneList: TimeZone[];
  public locationList: any;
  public companyDetails: any;
  public isFormLoading = true;
  public isSuccess = false;
  public responseMsg: any;
  public responseMsgSubStrBold: any;
  public responseMsgSubStrStart: any;
  public emailResponse: any;
  public emailResponseMsg: any;
  public showSuccessBox = false;
  public timer: any;
  public domainVal: any;
  public domainRecieved: any;
  public subscription = false;
  public isBtnLoading = false;
  public showSpinner = false;
  public errorMsg = false;
  public emailCheck = false;
  public showSuccessIcon = false;
  public showErrorIcon = false;
  public regexStr = /^[a-zA-Z0-9.]*$/;
  public specialCharAlert = false;
  public maxLength = 100;
  public successPage = false;
  public timeZoneFilter: Observable<TimeZone[]>;
  public LocationFilter: Observable<any[]>;

  public domainPrefix: any;
  public isRestrictOperator = false;
  public domainErrorMsg: any;
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
  constructor(
    public registrationServiceService: RegistrationServiceService,
    private cookie: CookieService,
    private appDetailsService: AppDetailsService
  ) {}

  ngOnInit() {
    this.isFormLoading = false;
    const MOBILE_PATTERN = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
    if (!this.appDetailsService.getAppDetails()) {
      const input = {
        // domain: window.location.hostname
        domain: 'oneclick-staging.wolkenservicedesk.com'
      };
      const formData: FormData = new FormData();
      formData.append('data', JSON.stringify(input));
      this.appDetailsService.getCompanyConfigDetails(formData).subscribe((value) => {
        if (value && value.data && value.data.hasOwnProperty('companyDetails')) {
          this.companyDetails = value.data.companyDetails;
          this.appDetailsService.setAppDetails(this.companyDetails);
         } else {
          this.isFormLoading = false;
         }
      }, (error) => {
        console.log(error);
        this.isFormLoading = false;
      });
    } else {
      this.isFormLoading = false;
    }
    this.registerForm = new FormGroup({
      userfName: new FormControl('', [Validators.required, Validators.maxLength(this.maxLength), this.registrationServiceService.emptyValueValidator.bind(this)]),
      userlName: new FormControl('', [Validators.maxLength(this.maxLength)]),
      email: new FormControl('', [Validators.required, this.registrationServiceService.emailIdValidator, Validators.email]),
      companyname: new FormControl('', [Validators.required, Validators.maxLength(this.maxLength),this.registrationServiceService.emptyValueValidator.bind(this)]),
      domain : new FormControl('', [Validators.required , Validators.maxLength(this.maxLength)]),
      phoneNumber : new FormControl('', [Validators.maxLength(20), Validators.pattern(MOBILE_PATTERN)]),
      locationName: new FormControl('', [Validators.required]),
      timezone: new FormControl('', [Validators.required]),
      // termsCondition: new FormControl('', [Validators.required])
    });
    this.fetchTimeZone();
    this.getLocationList();
    this.domainPrefix = this.appDetailsService.getDomainPrefix();
    this.isRestrictOperator = this.appDetailsService.getRestrictOperator();
  }

  fetchTimeZone() {
    this.registrationServiceService.fetchTimeZone().subscribe((value) => {
      if (value) {
      this.timezoneList = value.data.timeZoneList;
      this.setDefault(this.timezoneList);
      this.timeZoneFilter = this.registerForm.get('timezone').valueChanges
      .pipe(
        startWith<string | TimeZone>(''),
        map(filterValue => typeof filterValue === 'string' ? filterValue : filterValue.timeZoneName),
        map(timeZone => timeZone ? this.filterList(timeZone) : this.timezoneList.slice())
      );
    }
  },
      (error) => {
        console.log('Error', error);
      });
  }

  setDefault(list: TimeZone[]) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tf = new Date().getTimezoneOffset();
    if (list.some(val => val.timeZoneName === tz)) {
      const matchedObj = list.find(val => val.timeZoneName === tz);
      this.registerForm.controls.timezone.setValue(matchedObj);
    } else {
      if (list.some((val: any) => val.offset === tf)) {
        const matchedObj = list.find((val: any) => val.offset === tf);
        this.registerForm.controls.timezone.setValue(matchedObj);
      }
    }
  }

  filterList(filterValue: string): TimeZone[] {
    return this.timezoneList.filter((option: any) => option.timeZoneName.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
  }

  getLocationList() {
    this.registrationServiceService.fetchCompanyLocation().subscribe(
      (value) => {
        this.locationList = value.data.allLocationList;
        this.LocationFilter = this.registerForm.get('locationName').valueChanges
        .pipe(
          startWith<string | locationObj>(''),
          map(v => typeof v === 'string' ? v : v.selectedObj),
          map(selectedObj => selectedObj ? this.filter(selectedObj, this.locationList) : this.locationList.slice())
        );
      },
      (error) => {
        console.log('Error', error);
      });
  }

  // filterLocationList(filterValue: any): any[] {
  //   return this.locationList.filter(option => option.locationName.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
  // }

  filter(val: string, dropdownType: any): locationObj[] {
    switch (dropdownType) {
      case this.locationList: {
        return dropdownType.filter(option =>
          option.locationName.toLowerCase().includes(val.toLowerCase()));
      }
    }
  }

  register(form: FormGroup) {
    this.isBtnLoading = false;
    this.errorMsg = false;
    this.isSuccess = false;
    let formValue;
    let formDataValue;
    if (this.registerForm.valid && this.showSuccessIcon && this.emailCheck && !this.specialCharAlert) {
      this.isBtnLoading = true;
      let domainValue = this.registerForm.get('domain').value + '.wolkencare.com';
      if (this.domainPrefix) {
        domainValue = this.domainPrefix + domainValue;
      }
      formValue = {
        companyname: this.registerForm.value.companyname ? this.registerForm.value.companyname : null,
        domain: domainValue ? domainValue : null,
        email: this.registerForm.value.email ? this.registerForm.value.email : null,
        userfname: this.registerForm.value.userfName ? this.registerForm.value.userfName : null,
        userlname: this.registerForm.value.userlName ? this.registerForm.value.userlName : '',
        locationName: this.registerForm.value.locationName.locationName  ? this.registerForm.value.locationName.locationName : null,
        timezone: this.registerForm.value.timezone.timeZoneName ? this.registerForm.value.timezone.timeZoneName : null,
        phoneNumber: this.registerForm.value.phoneNumber ? this.registerForm.value.phoneNumber : null,
        //  phoneNumber: this.registerForm.value.phoneNumber ? this.registerForm.value.phoneNumber : null,
      };
      formDataValue = JSON.stringify(formValue);
    }
    if(formDataValue){
    this.registrationServiceService.registerUser(formDataValue).subscribe(
      (value: any) => {
        if (value) {
          setTimeout(() => {
          this.setTimer();
          this.showSuccessIcon = false;
          this.responseMsg = value.message;
          this.responseMsgSubStrBold = this.responseMsg.substring(31, this.responseMsg.length);
          this.responseMsgSubStrStart = this.responseMsg.substring(0, 31);

          if (this.responseMsg.startsWith('Instructions')) {
            this.isSuccess = true;
            this.formDirective.resetForm();
          } else {
            this.errorMsg = true;
            this.registerForm.get('email').setValue('');
            this.domainValidation();
          }
          this.isBtnLoading = false;
        }, 30000);
       } else {
        this.isBtnLoading = false;
       }
      },
      (error) => {
        console.log('Error', error);
        this.isBtnLoading = false;
      });
    } else {
      this.isBtnLoading = false;
    }
  }

    setTimer() {
    this.successPage = true;
    this.showSuccessBox = true;
    // this.timer = Observable.timer(5000);
    // this.subscription = this.timer.subscribe(() => {
    // this.showSuccessBox = false;
    // this.successPage = false;
    // });
  }

  omit_special_char(event) {
     let k;
     k = event.charCode;
     return((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  domainCreation(obj) {
    if (obj) {
      let newText;
      this.domainRecieved =  this.registerForm.value.companyname;
      const constructedDomain = this.domainRecieved.replaceAll('.', '');
      this.domainVal = constructedDomain;
      // this.domainVal = constructedDomain + '.wolkencare.com';
      newText = this.domainVal.split(/\s/).join('');
      this.registerForm.get('domain').setValue(newText.toLowerCase());
    } else {
      this.registerForm.get('domain').setValue('');
      this.showSuccessIcon = false;
      this.showErrorIcon = false;
    }
  }

  emailValidation(email) {
    this.emailCheck = false;
    this.registrationServiceService.emailValidation(email).subscribe(
      (value: any) => {
          this.emailResponse = value.message;
          this.emailResponseMsg = ' ';
          if (this.emailResponse.startsWith('Valid')) {
            if(this.isRestrictOperator){
                const value = this.registerForm.get('email').value;
                if(value.includes('+')){
                  this.emailCheck = false;
                  this.registerForm.get('email').setErrors({ notValidEmailId: true });
                } else {
                  this.emailCheck = true;
                }
            } else {
            this.emailCheck = true;
            }
          } else if (this.emailResponse.startsWith('User')) {
            this.emailCheck = false;
            this.emailResponseMsg = this.emailResponse;
          } else {
            this.emailCheck = false;
            this.emailResponseMsg = this.emailResponse;
          }
      },
      (error) => {
        console.log('error');
      });
  }

  domainValidation() {
    this.showSuccessIcon = false;
    this.showErrorIcon = false;
    this.specialCharAlert = false;
    let domainValue = this.registerForm.get('domain').value + '.wolkencare.com';
    if (this.domainPrefix) {
      domainValue = this.domainPrefix + domainValue;
    }
    if (this.regexStr.test(domainValue) === false) {
      this.specialCharAlert = true;
    }
    if (!(domainValue === '') && !(this.specialCharAlert)) {
    let responseVal;
    this.showSpinner = true;
    this.showSuccessIcon = false;
    this.showErrorIcon = false;
    this.registrationServiceService.domainNameValidation(domainValue).subscribe(
      (value: any) => {
        if (value) {
          this.showSpinner = false;
          responseVal = value.data.data;
          if ( responseVal.startsWith('New Domain')) {
            this.showSuccessIcon = true;
            this.showErrorIcon = false;
          }
       } else {
        this.showErrorIcon = true;
        this.registerForm.get('domain').setErrors({
          notValid: true
        });
        this.showSuccessIcon = false;
       }
      },
      (error) => {
        console.log('Error', error);
        this.domainErrorMsg = error.error.message;
        this.showSpinner = false;
        this.showErrorIcon = true;
        this.registerForm.get('domain').setErrors({
          notValid: true
        });
        this.showSuccessIcon = false;

      });

  }
}

displayFnc(search?: any): string | undefined {
  return search ? search.timeZoneName : undefined;
}

displayLocationFn(search?: any): string | undefined {
  return search ? search.locationName : undefined;
}

checkIfAgreedTerms() {
  const valid = false;
  // if(this.registerForm.get('termsCondition').value === true) {
  //   valid = false;
  // }
  // else {
  //   valid = true;
  // }
  return valid;
}

checkIfLocatiomEmptyInput() {
  if (!this.registerForm.get('locationName').value.locationId) {
    this.registerForm.get('locationName').setErrors({ notValid: true });
  }
}

checkIfTimezoneEmptyInput() {
  if (this.registerForm.get('timezone').value.length === 0) {
    this.registerForm.get('timezone').setErrors({ required: true });
  }
  if (!this.registerForm.get('timezone').value.timeZoneId) {
    this.registerForm.get('timezone').setErrors({ notValid: true });
  }
}
}
