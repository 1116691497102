import { Injectable, ɵConsole } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// tslint:disable-next-line: max-line-length
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


@Injectable({
  providedIn: 'root'
})
export class RegistrationServiceService {
  private headers = new HttpHeaders({'Content-Type': 'text/html'});
  constructor(private http: HttpClient) { }

  emailIdValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      const emailRegex = new RegExp(EMAIL_REGEX);
      if (!emailRegex.test(control.value)) {
        return { notValidEmailId: true };
      }
      return null;
    }
  }

 fetchTimeZone(): Observable<any> {
  return this.http.get('care/register/timezones.do');
}

  fetchCompanyLocation(): Observable<any> {
    return this.http.get('care/register/location.do');
  }

  registerUser(formData) {
    return this.http.post('care/register/registerUser.do', formData);
  }

  domainNameValidation(domVal) {
    const params = new HttpParams().set('domainName', domVal);
    return this.http.get('care/register/domainNameCheck.do', {params});
  }

  emailValidation(email) {
    const params = new HttpParams().set('address', email.replaceAll('+', '%2B'));
    return this.http.get('care/register/validateEmail.do', {params});
  }

  emptyValueValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      if (control.value.trim().length === 0) {
        return { 'emptyValue': true };
      }
    }
    return null;
  }
}
