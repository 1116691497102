import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerificationPageComponent } from './verification-page.component';


const routes: Routes = [
  {
    path : '',
    component : VerificationPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerificationPageRoutingModule { }
