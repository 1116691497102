
<div fxLayout="column" fxLayoutAlign="center center" class="outerFlex">
  <div fxLayoutAlign="center center" fxLayout="row">
   
    <!-- success -->
    <div *ngIf="verificationStatus == true" class="successModal" align="center">
      <div class="wolken-logo">
          <img src="assets/images/WOLKEN_CARE_LOGO.svg"> 
       </div>
    <div class="successModal-header">
      <h5>E-mail Verification</h5>
    </div>
    <div class="success-img">
    <img src="assets/images/SUCCESS.png"> 
    <p>Success</p>
  </div>

    <div class="success-msg">
      <p align="center">{{responseMsgSubStrStart}}<span style="color:black;font-weight:500;">{{responseMsgSubStrBold}}</span></p>
   </div>
  </div>

  <!-- process -->
    <div *ngIf="verifyingEmail" class="verifyModal" align="center">
        <div class="wolken-logo">
            <img src="assets/images/WOLKEN_CARE_LOGO.svg"> 
         </div>
      <div class="successModal-header">
        <h5>E-mail Verification</h5>
      </div>
      <div *ngIf="onSuccess" id="loadingProgressG">
        <div id="loadingProgressG_1" class="loadingProgressG"></div>
      </div>
      <div class="verify-msg">
      <p>Please wait...</p>
      <p>We are verifying your e-mail</p>
       </div>
      <div class="success-msg">
        <p align="center">{{verificationMessage}}</p>
     </div>
    </div>

    <!-- failed -->
      <div *ngIf="verificationStatus == false" class="successModal" align="center">
      <div class="wolken-logo">
          <img src="assets/images/WOLKEN_CARE_LOGO.svg"> 
       </div>
    <div class="successModal-header">
      <h5>E-mail Verification</h5>
    </div>
    <div class="success-img-fail">
    <img src="assets/images/ERROR.png"> 
    <p>Oops!</p>
  </div>

    <div class="success-msg" *ngIf="isLoginFails">
      <p>{{responseMsgSubStrStart}}<span style="color:black;font-weight:500;">{{responseMsgSubStrBold}}</span></p>
   </div>
   <div class="success-msg" *ngIf="isLoginSupportText">
    <p align="center">{{verificationMessage}}</p>
 </div>
  </div>
  </div>
</div>

